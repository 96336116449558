
import { defineComponent, ref } from 'vue';

import { Page } from '@/types/models';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import PageSkeleton from '@/components/skeleton/PageSkeleton.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import DisplayMarkdown from '@/components/shared/DisplayMarkdown.vue';
import usePages from '@/hooks/pages/use-pages';

export default defineComponent({
  components: { DisplayError, Skeleton, PageSkeleton, DisplayMarkdown },
  props: {
    pageSlug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const page = ref<Page>();
    const { loading, errors, getPage } = usePages().useGetPage();

    getPage(props.pageSlug).then((data) => (page.value = data));

    return {
      page,
      loading,
      errors,
    };
  },
});
