<template>
  <div class="mt-8" v-html="markdownToHtml(content)" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { marked } from 'marked';

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup() {
    const markdownToHtml = (content: string) => {
      return marked.parse(content);
    };

    return {
      markdownToHtml,
    };
  },
});
</script>
