<template>
  <main class="max-w-screen-2xl mx-auto px-4 py-10 sm:py-16 lg:px-16 lg:w-10/12">
    <DisplayError :errors="errors" />

    <Skeleton :show="loading" class="w-full flex items-center justify-center">
      <section v-if="page" class="w-full prose lg:prose-xl">
        <DisplayMarkdown :content="page.content" />
      </section>

      <div v-else class="h-80">
        <h6 class="text-gray-700 md:text-lg">Échec du chargement de la page</h6>
      </div>

      <template #fallback>
        <PageSkeleton />
      </template>
    </Skeleton>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import { Page } from '@/types/models';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import PageSkeleton from '@/components/skeleton/PageSkeleton.vue';
import DisplayError from '@/components/shared/DisplayError.vue';
import DisplayMarkdown from '@/components/shared/DisplayMarkdown.vue';
import usePages from '@/hooks/pages/use-pages';

export default defineComponent({
  components: { DisplayError, Skeleton, PageSkeleton, DisplayMarkdown },
  props: {
    pageSlug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const page = ref<Page>();
    const { loading, errors, getPage } = usePages().useGetPage();

    getPage(props.pageSlug).then((data) => (page.value = data));

    return {
      page,
      loading,
      errors,
    };
  },
});
</script>
