import { reactive, toRefs, ToRefs } from 'vue';

import { Page } from '@/types/models';
import { useGetPage, UseGetPage } from './page-get';

const state: State = reactive({
  pages: {},
});

export const getPagesState = (): State => state;

const usePages: UsePages = () => {
  return {
    ...toRefs(state),
    useGetPage,
  };
};

export default usePages;

type State = {
  pages: Record<string, Page>;
};

type UsePages = () => ToRefs<State> & {
  useGetPage: UseGetPage;
};
