import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import { Page } from '@/types/models';
import { Ref } from 'vue';

import { getPagesState } from './use-pages';

export const useGetPage: UseGetPage = () => {
  const state = getPagesState();
  const { errors, loading, request } = useRequest();

  const getPage = async (slug: string) => {
    const cacheKey = `page:${slug}`;

    if (state.pages[cacheKey]) return state.pages[cacheKey];

    const data = await request<Page[]>({ url: `/pages?_slug=${slug}` });

    if (data && data[0]) {
      state.pages[cacheKey] = data[0];
      return data[0];
    }
  };

  return { loading, errors, getPage };
};

export type UseGetPage = () => {
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
  getPage(slug: string): Promise<Page | undefined>;
};
